import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../../../actions"

import { Container, Button, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Grid, GridColumn, Input, Loader, Popup } from 'semantic-ui-react'

class Clients extends Component {
    state = { clients: null, activePage: 0, itemsPerPage: 20, showFilterOptions: false, filterFYE: [], filterMonthOfAgreement: [] }

    constructor(props) {
        super(props)
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this)
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)
    }

    componentDidMount() {
        this.props.fetchClients()
        this.props.fetchRoles()
    }

    renderTableRows() {
        var clientsWithDocs = []
        for (const client of this.props.clients) {
            if (client.clientDocs == null || client.clientDocs.length === 0) continue
            clientsWithDocs.push(client)
        }
        var data = []
        if (this.state.isSearching) {
            for (var x = 0; x < clientsWithDocs.length; x++) {
                let cli = clientsWithDocs[x]
                if (cli.clientInfo.companyName != null) {
                    if (cli.clientInfo.companyName.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.group != null) {
                    if (cli.clientInfo.group.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
            }
        } else {
            data = clientsWithDocs.slice(this.state.activePage*this.state.itemsPerPage, this.state.activePage*this.state.itemsPerPage+this.state.itemsPerPage)
        }
        var rows = []
        for (var i = 0; i < data.length; i++) {
            const client = data[i]
            let a = this.getDocCountOfStatus('Received', client.clientDocs)
            let b = this.getDocCountOfStatus('In Processing', client.clientDocs)
            let c = this.getDocCountOfStatus('Ready to Return', client.clientDocs)
            rows.push(
                <TableRow key={i}>
                    <TableCell>{client.clientInfo.companyName}</TableCell>
                    <TableCell>{client.clientInfo.roc}</TableCell>
                    <TableCell>{a}</TableCell>
                    <TableCell>{b}</TableCell>
                    <TableCell>{c}</TableCell>
                    <TableCell>{a + b + c}</TableCell>
                    <TableCell collapsing>
                        <Button circular basic icon='eye' onClick={this.handleViewClicked.bind(this, client)}/>
                    </TableCell>
                </TableRow>
            )
        }
        return rows
    }

    renderTable() {
        if (this.props.clients == null) {
            return <Loader active size="large">Loading</Loader>
        } else {
            return (
                <Table celled striped compact>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Company Name</TableHeaderCell>
                            <TableHeaderCell width='2'>ROC</TableHeaderCell>
                            <TableHeaderCell collapsing>Received</TableHeaderCell>
                            <TableHeaderCell collapsing>In Processing</TableHeaderCell>
                            <TableHeaderCell collapsing>Ready to Return</TableHeaderCell>
                            <TableHeaderCell collapsing>Total</TableHeaderCell>
                            <TableHeaderCell></TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                    {this.renderTableRows()}
                    </TableBody>
                </Table>
            )
        }
    }

    render() {
        return (
            <Container>
                <br /><br />
                <Grid>
                <GridColumn floated='left' width={5}><h2>Clients with Pending Docs</h2></GridColumn>
                    <GridColumn width={6}>
                        <Popup trigger={<Input fluid placeholder='Search client...' onChange={this.handleChangeSearchField} />} on='focus' wide='very' position='bottom left' 
                        header='Client Search' content="Search by company name, group."/>
                    </GridColumn>
                    <GridColumn floated='right' width={5}>
                    </GridColumn>
                </Grid>
                {this.renderTable()}
                <br /><br /><br />
            </Container>
        )
    }

    handleChangeSearchField = e => {
        const { value } = e.target
        if (value.length < 2 && !this.state.isSearching) {
            return
        }
        if (value.length < 2) {
            this.setState({ isSearching: false })
        } else {
            this.setState({ isSearching: true, searchString: value })
        }
    }

    handlePageChange = (e, { activePage}) => {
        let page = activePage-1
        this.setState({ activePage: page })
    }

    handleViewClicked(client) {
        this.props.handleViewDoc(client)
    }

    handleDeleteClicked = client => {
        if (this.state.showDeleteConfirmationPopup) return
        const contentString = "Delete client '" + client.clientInfo.companyName + "'?"
        this.setState({
            idToDelete: client._id,
            deleteConfirmContentString: contentString,
            showDeleteConfirmationPopup: true
        })
    }

    handleDeleteCancel = () =>
        this.setState({ showDeleteConfirmationPopup: false })

    handleDeleteConfirm = () => {
        const dict = { id: this.state.idToDelete }
        this.props.deleteClient(dict, () => {
            this.setState({ showDeleteConfirmationPopup: false })
            this.props.fetchClients()
            this.props.onDeleteSuccess()
        })
    }

    getDocCountOfStatus(status, records) {
        var count = 0
        for (const record of records) {
            for (const doc of record.docs) {
                if (doc.status === status) {
                    count+=1
                }
            }
        }
        return count
    }
}

function mapStateToProps({ clients, roles, user }) {
    return { clients, roles, user }
}

export default connect(mapStateToProps, actions)(Clients)

