import React, { Component } from "react"
import { format } from "date-fns"
import { Container, Button, ButtonContent, Divider, Header, Icon, Label, Table, TableHeader, TableRow, TableCell, TableHeaderCell, TableBody, Popup, Grid, GridRow } from 'semantic-ui-react'
import ClientDoc from "./ClientDoc"
import ClientViewDoc from "./ClientViewDoc"

const addDocButtonStyle = {
    width: "140px"
}

const labelStyle = {
    marginRight: "8px", marginTop: "5px", marginBottom: "5px"
}

class ClientDocs extends Component {
    state = { page: "addDocument2", records: [] }

    constructor(props) {
        super(props)
        this.handleAddDocClicked = this.handleAddDocClicked.bind(this)
        this.handleAddDocCancelClicked = this.handleAddDocCancelClicked.bind(this)
        this.handleAddDocSaveClicked = this.handleAddDocSaveClicked.bind(this)
        this.handleViewDocBackClicked = this.handleViewDocBackClicked.bind(this)
        this.handleUpdateClientDocs = this.handleUpdateClientDocs.bind(this)
        this.handleAddDoc = this.handleAddDoc.bind(this)
    }

    componentDidMount() {
        var savedDict = {}
        if (!!this.props.savedDict) {
            savedDict = this.props.savedDict
        } else {
            return
        }
        this.setState({
            records: savedDict
        })
        if (this.props.redirectTo === 'viewDoc') {
            for (const record of savedDict) {
                if (record.id === this.props.recordIDRedirect) {
                    this.setState({ page: "viewDocument", recordToView: record })
                    this.props.resetDocsRedirect()
                    break
                }
            }
        }
    }

    renderLabels(arr, statusArr) {
        var labels = []
        for (const [i, str] of arr.entries()) {
            var color = ""
            if (statusArr[i] === "Returned") color = 'black'
            if (statusArr[i] === "Ready to Return") color = 'red'
            if (statusArr[i] === "In Processing") color = 'blue'
            if (statusArr[i] === "Received") color = 'purple'
            labels.push(<Label style={labelStyle} size='large' basic color={color}>{str}</Label>)
        }
        return labels
    }

    renderTableRows() {
        if (!!this.state.records) {
            const records = this.state.records.slice().reverse()
            var rows = []
            for (var i = 0; i < records.length; i++) {
                let dict = records[i]
                var stringArr = []
                var statusArr = []
                var showEditButton = true
                for (const doc of dict.docs) {
                    var str = ""
                    str += doc.type
                    if (!!doc.description) {
                        if (doc.description.length > 0) {
                            str += " (" + doc.description + ")"
                        }
                    }
                    stringArr.push(str)
                    statusArr.push(doc.status)
                    if (doc.status !== 'Received' || this.props.viewOnly) showEditButton = false
                }
                rows.push(
                    <TableRow key={i}>
                        <TableCell collapsing><Button circular basic icon='pencil' disabled={!showEditButton} onClick={this.handleEditClicked.bind(this, i, dict)}/><Button circular basic icon='eye' onClick={this.handleViewClicked.bind(this, dict)}/></TableCell>
                        <TableCell>{format(dict.date, "d MMMM, yyyy 'at' hh:mm a")}</TableCell>
                        <TableCell>{dict.remarks}</TableCell>
                        <TableCell>{this.renderLabels(stringArr, statusArr)}</TableCell>
                        <TableCell textAlign="center">{dict.docs.length}</TableCell>
                        <TableCell>{dict.user}</TableCell>
                    </TableRow>
                )
            }
            return rows
        }
    }

    renderTable() {
        return (
            <Table celled striped compact>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        <TableHeaderCell width='2'>Date</TableHeaderCell>
                        <TableHeaderCell width='3'>Remarks</TableHeaderCell>
                        <TableHeaderCell>Documents
                            <Popup wide trigger={<Icon style={{ marginLeft: 5 }} circular name="info" size="small" />} position="bottom left">
                                <Grid style={{ paddingLeft: 16, paddingRight: 16 }} centered>
                                <h4 style={{ marginTop: 20, marginBottom: 5 }}>Color coding for Document Status:</h4>
                                <GridRow>
                                    <Label style={{ marginBottom: 10 }} basic size='large' color='purple'>Received</Label>
                                    Document has just been received from the client. Pending print and stick QR code on the document.
                                </GridRow>
                                <Divider style={{ marginTop: 3, marginBottom: 3 }} />
                                <GridRow textAlign='center'>
                                    <Label style={{ marginBottom: 10 }} basic size='large' color='blue'>In Processing</Label>
                                    Document has already been handed over to the Audit Team, and they are now processing it.
                                </GridRow>
                                <Divider style={{ marginTop: 3, marginBottom: 3 }} />
                                <GridRow textAlign='center'>
                                    <Label style={{ marginBottom: 10 }} basic size='large' color='red'>Ready to Return</Label>
                                    Document has been received from the Audit Team, and its QR code has already been scanned.
                                </GridRow>
                                <Divider style={{ marginTop: 3, marginBottom: 3 }} />
                                <GridRow textAlign='center'>
                                    <Label style={{ marginBottom: 10 }} basic size='large' color='black'>Returned</Label>
                                    Document has been returned to the client, and the client's e-signature has been received.
                                </GridRow>
                                </Grid>
                            </Popup>
                        </TableHeaderCell>
                        <TableHeaderCell textAlign="center" width='1'>Total</TableHeaderCell>
                        <TableHeaderCell width='2'>Created By</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {this.renderTableRows()}
                </TableBody>
            </Table>
        )
    }

    renderEmptyState() {
        if (!!this.state.records) {
            if (this.state.records.length === 0) return <h3 style={{marginTop: 30, marginBottom: 30}}>No records of document processing for this client.</h3>
        }
    }

    renderTransactionsTable() {
        if (!!this.state.records) {
            if (this.state.records.length > 0) return <div><br />{this.renderTable()}</div>
        }
    }

    renderAddDocumentsButton() {
        if (this.props.viewOnly || !this.props.canCreateDoc) return
        return (
            <div>
                <Button style={addDocButtonStyle} animated='fade' color='red' onClick={this.handleAddDocClicked}>
                    <ButtonContent hidden>Create New Record</ButtonContent>
                    <ButtonContent visible>
                        <Icon name='plus' />
                    </ButtonContent>
                </Button>
                <br />
            </div>)
    }

    renderContent() {
        if (this.state.page === "addDocument") {
            return <ClientDoc clientID={this.props.clientID} onSave={this.handleAddDocSaveClicked} onCancel={this.handleAddDocCancelClicked}></ClientDoc>
        } else if (this.state.page === "viewDocument") {
            return <ClientViewDoc viewOnly={this.props.viewOnly} updateClientDocs={this.handleUpdateClientDocs} clientID={this.props.clientID} recordToView={this.state.recordToView} onBack={this.handleViewDocBackClicked}></ClientViewDoc>
        } else if (this.state.page === "editDocument") {
            return <ClientViewDoc canEditDocs viewOnly={this.props.viewOnly} updateClientDocs={this.handleUpdateClientDocs} clientID={this.props.clientID} recordToView={this.state.recordToView} onBack={this.handleViewDocBackClicked} handleAddDoc={this.handleAddDoc}></ClientViewDoc>
        } else {
            return (<div>
                <Divider horizontal><Header as='h4'><Icon name='file alternate outline' />Document Tracking Records</Header></Divider>
                {this.renderEmptyState()}
                {this.renderAddDocumentsButton()}
                {this.renderTransactionsTable()}
            </div>)
        }
    }

    render() {
        return (
            <Container key={this.props.key}>{this.renderContent()}<br /><br /><br /><br /><br /><br /><br />
            {this.props.isFromDocuments ? 
            <div style={{ position: "fixed", bottom: 0, left: 0, right: 0, background: "#fff", borderTop: "2px solid #ccc", padding: "10px", zIndex: 100 }}>
                <Container>
                    <Button style={{ marginTop: 10, marginRight: 15, marginBottom: 15 }} color='red' onClick={this.props.onBackClicked}><Icon name="reply" />Back</Button>
                </Container>
            </div> : null }
            </Container>
        )
    }

    handleUpdateClientDocs(data, recordID) {
        this.props.updateClientDocs(data, recordID)
    }

    handleViewClicked(dict) {
        this.setState({ page: "viewDocument", recordToView: dict })
    }

    handleEditClicked(i, dict) {
        this.setState({ page: "editDocument", recordToView: dict, indexEditing: i })
    }

    handleAddDocClicked() {
        this.setState({ page: "addDocument" })
    }

    handleAddDocCancelClicked() {
        this.setState({ page: "transactionHistory" })
    }

    handleViewDocBackClicked() {
        this.setState({ page: "transactionHistory" })
    }

    handleAddDocSaveClicked(dict) {
        var arr = this.state.records
        arr.push(dict)
        this.setState({ records: arr })
        this.setState({ page: "viewDocument", recordToView: dict })
    }

    handleAddDoc(dict) {
        var arr = this.state.records
        arr[this.state.indexEditing].docs.push(dict)
        this.setState({ records: arr })
        this.setState({ page: "editDocument", recordToView: this.state.records[this.state.indexEditing]})
    }
}

export default ClientDocs