import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../../../actions"
import { Container, Button, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Confirm, Icon, Grid, GridColumn, Input, ButtonContent, Loader, Pagination, Popup, Checkbox, Segment } from 'semantic-ui-react'

import { saveAs } from "file-saver"
import writeXlsxFile from 'write-excel-file'
// import readXlsxFile from 'read-excel-file'
// import axios from 'axios'
import { format } from "date-fns"
import { fields, departments } from '../../../fields'

const downloadButtonStyle = {
    width: "80px",
    marginRight: '10px'
}

const checkboxStyle = {
    marginTop: '10px',
    marginBottom: '20px',
    marginRight: '15px'
}

const createButtonStyle = {
    width: '155px'
}

const filterButtonStyle = {
    marginRight: '15px'
}

var filteredClients = []

class Clients extends Component {
    state = { clients: null, activePage: 0, itemsPerPage: 20, showFilterOptions: false, filterFYE: [], filterMonthOfAgreement: [] }

    constructor(props) {
        super(props)
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this)
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)
        this.handleDownloadClicked = this.handleDownloadClicked.bind(this)
        this.handleGenerateCorpSecReportClicked = this.handleGenerateCorpSecReportClicked.bind(this)
        this.handleMiscButtonClicked = this.handleMiscButtonClicked.bind(this)
    }

    componentDidMount() {
        this.props.fetchClients()
        this.props.fetchRoles()
        
        
        // if (!!this.props.user) {
        //     if (this.props.user.email === "seantan.mouse@gmail.com") {
        //         const input = document.getElementById('excel-upload')
        //         input.addEventListener('change', () => {

        //             // axios.post('/api/clients/clear_all_corpsec_data', {}) // IMPORTANT: REMOVE THIS WHEN NOT NEEDED
        //             // .then((res) => {
        //             //     console.log("clear_all_corpsec_data success")
                      
        //             // })
        //             // .catch((err) => {
        //             //     console.log(`clear_all_corpsec_data failed with error: ${err}`)
        //             // })

        //             readXlsxFile(input.files[0]).then((rows) => {
        //                 rows.splice(0,1) // CHANGE THIS ACCORDINGLY
        //                 var notFoundCounter = 0
        //                 var foundCounter = 0
        //                 let dispatchGroup = new DispatchGroup()
        //                 var totalCounter = 0
        //                 var skippedCounter = 0 // for blank ROC
                        
        //                 for (let row of rows) {
        //                     // Sixth round of import – replace all corp sec data (REMOVE TOP AXIOS.POST FUNCTION WHEN NOT NEEDED)
        //                     if (row[1] === "") {
        //                         skippedCounter += 1
        //                         continue
        //                     }
        //                     let dict = { mappingUEN: row[0], corpSec: { dateAcraStrikeOff: row[2] } }
        //                     let token = dispatchGroup.enter()
        //                     console.log("now doing: " + row[0])
        //                     this.props.uploadStrikeOffDateData(dict, (statusCode) => {
        //                         if (statusCode === 201) {
        //                             notFoundCounter += 1
        //                             console.log("not found: " + dict.mappingName + " – " + row[0])
        //                         } else if (statusCode === 200) {
        //                             foundCounter +=1
        //                         }
        //                         dispatchGroup.leave(token)
        //                     })
        //                     console.log(dict)
        //                     totalCounter += 1
        //                     if (totalCounter > 0) {
        //                         // break
        //                     }

        //                     // Fifth round of import – replace all corp sec data (REMOVE TOP AXIOS.POST FUNCTION WHEN NOT NEEDED)
        //                     // if (row[1] === "") {
        //                     //     skippedCounter += 1
        //                     //     continue
        //                     // }
        //                     // console.log(row[12])
        //                     // var monthOfAgreementData = []
        //                     // if (!!row[12]) {
        //                     //     if (row[12].toString().includes(',')) {
        //                     //         monthOfAgreementData = row[12].toString().split(',')
        //                     //     } else {
        //                     //         monthOfAgreementData.push(row[12])
        //                     //     }
        //                     // }
        //                     // let dict = { mappingUEN: row[0], corpSec: { typeOfEngagement: row[2], secFileNumber: row[3], group: row[4], dateAcraStrikeOff: row[6], noStrikeOffDate: row[7], FYE: row[8], lastAccountFiled: row[9], agreementNo: row[10], dateOfAgreement: row[11],
        //                     //             monthOfAgreement: monthOfAgreementData, remarksForMonthOfAgreement: row[13], commonSeal: row[14], nameOfSecretary: row[15], dateApptAsSec: row[16], dateResignAsSec: row[17], nameOfSecretary2: row[18], dateApptAsSec2: row[19], dateResignAsSec2: row[20], 
        //                     //             remarksForApptResignAsSec: row[21], howManyShareholderAgreement: row[22], remarks: row[23], others: row[24] } }
        //                     // let token = dispatchGroup.enter()
        //                     // console.log("now doing: " + row[0])
        //                     // this.props.replaceCorpSecDataToAllClients(dict, (statusCode) => {
        //                     //     if (statusCode === 201) {
        //                     //         notFoundCounter += 1
        //                     //         console.log("not found: " + dict.mappingName + " – " + row[0])
        //                     //     } else if (statusCode === 200) {
        //                     //         foundCounter +=1
        //                     //     }
        //                     //     dispatchGroup.leave(token)
        //                     // })
        //                     // console.log(dict)
        //                     // totalCounter += 1
        //                     // if (totalCounter > 0) {
        //                     //     // break
        //                     // }
                            
        //                     // Fourth round of import – phone (secondary) for client info
        //                     // if (row[1] === "") {
        //                     //     skippedCounter += 1
        //                     //     continue
        //                     // }
        //                     // let dict = { roc: row[1], clientInfo: { phone2: row[2] } }
        //                     // let token = dispatchGroup.enter()
        //                     // console.log("now doing: " + row[0])
        //                     // this.props.addAuditDataToAllClients(dict, (statusCode) => {
        //                     //     if (statusCode === 201) {
        //                     //         notFoundCounter += 1
        //                     //         console.log("not found: " + dict.mappingName + " – " + row[0])
        //                     //     } else if (statusCode === 200) {
        //                     //         foundCounter +=1
        //                     //     }
        //                     //     dispatchGroup.leave(token)
        //                     // })
        //                     // console.log(dict)
        //                     // totalCounter += 1
        //                     // if (totalCounter > 0) {
        //                     //     // break
        //                     // }

        //                     // Third round of import – only corp sec
        //                     // NOTE: fields to find and replace in Excel before import: No Strike Off Date (Yes -> yes), Month of FYE and Month Agreement (Jan -> 1), 
        //                     // let dict = { mappingName: row[0], corpSec: { customerNo: row[1], group: row[2], dateOfIncorp: row[3], dateAcraStrikeOff: row[4], noStrikeOffDate: row[5], FYE: row[6], lastAccountFiled: row[7], agreementNo: row[8], dateOfAgreement: row[9],
        //                     //             monthOfAgreement: row[10], remarksForMonthOfAgreement: row[11], commonSeal: row[12], nameOfSecretary: row[13], dateApptAsSec: row[14], dateResignAsSec: row[15], nameOfSecretary2: row[16], dateApptAsSec2: row[17], dateResignAsSec2: row[18], 
        //                     //             remarksForApptResignAsSec: row[19], howManyShareholderAgreement: row[20], remarks: row[21], others: row[22] } }
        //                     // let token = dispatchGroup.enter()
        //                     // this.props.addAuditDataToAllClients(dict, (statusCode) => {
        //                     //     if (statusCode === 201) {
        //                     //         notFoundCounter += 1
        //                     //     } else if (statusCode === 200) {
        //                     //         foundCounter +=1
        //                     //     }
        //                     //     dispatchGroup.leave(token)
        //                     // })

        //                     // Second round of import – only audit
        //                     // let dict = { mappingName: row[0], audit: { stocktakeRequired: row[4], auditAppointmentDate: row[6], consentLetterDate: row[7], auditResignationLetterDate: row[9], lastAccountAuditedDate: row[10], remarks: row[11], others: row[12] } }
        //                     // let token = dispatchGroup.enter()
        //                     // this.props.addAuditDataToAllClients(dict, (statusCode) => {
        //                     //     if (statusCode === 201) {
        //                     //         notFoundCounter += 1
        //                     //     } else if (statusCode === 200) {
        //                     //         foundCounter +=1
        //                     //     }
        //                     //     dispatchGroup.leave(token)
        //                     // })

        //                     // First round of import
        //                     // let client = { clientInfo: { clientType: row[0], companyName: row[1], roc: row[2], entityType: row[3], suspendPayment: row[4], oneContactPoint: row[5], group: row[6], remarksInfo: row[7], othersInfo: row[8], registeredAddress: row[9], 
        //                     //                 mailingAddress: row[10], phone1: row[11], phone2: row[12], fax: row[13], remarksContact: row[14], auth1Name: row[15], auth1Position: row[16], auth1Mobile: row[17], auth1Email: row[18], auth1OptOutEmail: row[19], auth1Remarks: row[20], 
        //                     //                 auth1Others: row[21], auth2Name: row[22], auth2Position: row[23], auth2Mobile: row[24], auth2Email: row[25], auth2OptOutEmail: row[26], auth2Remarks: row[27], auth2Others: row[28], auth3Name: row[29], auth3Position: row[30],
        //                     //                 auth3Mobile: row[31], auth3Email: row[32], auth3OptOutEmail: row[33], auth3Remarks: row[34], auth3Others: row[35] }, 
        //                     //                 businessDev: { quotationRemarks: row[36], feedbackReceived: row[37], remarks: row[38], others: row[39] },
        //                     //                 corpSec: {customerNo: row[40], group: row[41], dateOfIncorp: row[42], dateAcraStrikeOff: row[43], noStrikeOffDate: row[44], FYE: row[45], lastAccountFiled: row[46], agreementNo: row[47], dateOfAgreement: row[48],
        //                     //                     monthOfAgreement: row[49], commonSeal: row[50], nameOfSecretary: row[51], dateApptAsSec: row[52], dateResignAsSec: row[53], nameOfSecretary2: row[54], dateApptAsSec2: row[55], dateResignAsSec2: row[56], remarksForApptResignAsSec: row[57],
        //                     //                     howManyShareholderAgreement: row[58], remarks: row[59], others: row[60] },
        //                     //                 operations: { awpDrawerNo: row[61], ctDrawerNo: row[62], extCorpSecName: row[63], extCorpSecEmail: row[64], extCorpSecAddress: row[65], dateOfAppointment: row[66], reportToTourismBoard: row[67], remarks: row[68], others: row[69] },
        //                     //                 auditTrail: { createdBy: this.props.user.name, createdAt: Date() }, mappingName: row[70] }
        //                     // clients.push(client)
        //                     // this.props.createClient(client, (success) => {
        //                     //     if (success) {
        //                     //         console.log('success') 
        //                     //     } else {
        //                     //         alert('Failure creating client, please try again')
        //                     //     }
        //                     // })
        //                 }
        //                 dispatchGroup.notify(() => { 
        //                     console.log("found counter:")
        //                     console.log(foundCounter)
        //                     console.log("not found counter:")
        //                     console.log(notFoundCounter)
        //                     console.log("skipped counter:")
        //                     console.log(skippedCounter)
        //                 })
        //             })
                    
        //         })
        //     }
        // }
            
    }

    renderEditButton(client) {
        if (this.props.user == null) {   
            return
        } 
        if (!!this.props.user.role) {
            if (this.props.user.role.name.toLowerCase() === 'superadmin') {
                return <Button circular basic icon='edit' onClick={this.handleEditClicked.bind(this, client)}/>
            } else if (!!this.props.permissions) {
                var hasEditPermissions = false
                for (let permission of this.props.permissions) {
                    if (permission.write) {
                        hasEditPermissions = true
                        break
                    }
                }
                if (hasEditPermissions) {
                    return <Button circular basic icon='edit' onClick={this.handleEditClicked.bind(this, client)}/>
                } else {
                    return
                }
            } else {
                return
            }
        } else {
            return
        }
    }

    renderDeleteButton(client) {
        if (this.props.user == null) {   
            return
        } 
        if (!!this.props.user.role) {
            if (this.props.user.role.name.toLowerCase() === 'superadmin') {
                return (
                    <Button circular basic icon onClick={this.handleDeleteClicked.bind(this, client)}>
                        <Confirm open={this.state.showDeleteConfirmationPopup} onCancel={this.handleDeleteCancel} onConfirm={this.handleDeleteConfirm} header="Confirmation" content={this.state.deleteConfirmContentString} cancelButton="Cancel" confirmButton="Delete"/>
                        <Icon name="trash alternate" />
                    </Button>
                )
            } else {
                return
            }
        } else {
            return
        }
    }

    renderTableRows() {
        var data = []
        if (this.state.isSearching) {
            for (var x = 0; x < this.props.clients.length; x++) {
                let cli = this.props.clients[x]
                if (!doesClientSatisfyFilters(cli, this.state.filterFYE, this.state.filterMonthOfAgreement)) {
                    continue
                }
                if (cli.clientInfo.companyName != null) {
                    if (cli.clientInfo.companyName.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.group != null) {
                    if (cli.clientInfo.group.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth1Name != null) {
                    if (cli.clientInfo.auth1Name.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth1Mobile != null) {
                    if (cli.clientInfo.auth1Mobile.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth1Email != null) {
                    if (cli.clientInfo.auth1Email.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth2Name != null) {
                    if (cli.clientInfo.auth2Name.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth2Mobile != null) {
                    if (cli.clientInfo.auth2Mobile.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth2Email != null) {
                    if (cli.clientInfo.auth2Email.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth3Name != null) {
                    if (cli.clientInfo.auth3Name.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth3Mobile != null) {
                    if (cli.clientInfo.auth3Mobile.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth3Email != null) {
                    if (cli.clientInfo.auth3Email.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth4Name != null) {
                    if (cli.clientInfo.auth4Name.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth4Mobile != null) {
                    if (cli.clientInfo.auth4Mobile.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
                if (cli.clientInfo.auth4Email != null) {
                    if (cli.clientInfo.auth4Email.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(cli)
                        continue
                    }
                }
            }
            filteredClients = data
        } else {
            if (this.state.showFilterOptions) {
                var arr = []
                var arr2 = this.props.clients
                for (let item of arr2) {
                    if (doesClientSatisfyFilters(item, this.state.filterFYE, this.state.filterMonthOfAgreement)) {
                        arr.push(item)
                    }
                }
                filteredClients = arr
                data = arr.slice(this.state.activePage*this.state.itemsPerPage, this.state.activePage*this.state.itemsPerPage+this.state.itemsPerPage)
            } else {
                data = this.props.clients.slice(this.state.activePage*this.state.itemsPerPage, this.state.activePage*this.state.itemsPerPage+this.state.itemsPerPage)    
            }
        }
        var rows = []
        for (var i = 0; i < data.length; i++) {
            const client = data[i]
            if (!client.clientInfo) {
                continue
            }
            rows.push(
                <TableRow key={i}>
                <TableCell collapsing>
                    {this.renderDeleteButton(client)}
                    <Button circular basic icon='eye' onClick={this.handleViewClicked.bind(this, client)}/>
                    {this.renderEditButton(client)}
                </TableCell>
                    <TableCell>{client.clientInfo.companyName}</TableCell>
                    <TableCell>{client.clientInfo.roc}</TableCell>
                </TableRow>
            )
        }
        return rows
    }

    renderTable() {
        if (this.props.clients == null) {
            return <Loader active size="large">Loading</Loader>
        } else {
            return (
                <Table celled striped compact>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell></TableHeaderCell>
                            <TableHeaderCell>Company Name</TableHeaderCell>
                            <TableHeaderCell width='2'>ROC</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                    {this.renderTableRows()}
                    </TableBody>
                </Table>
            )
        }
    }

    renderDownloadButton() {
        if (!!this.props.user) {
            if (!!this.props.user.role) {
                if (this.props.user.role.name.toLowerCase() === 'superadmin') {
                    return (
                        <Button style={downloadButtonStyle} animated='fade' color='red' floated='right' onClick={this.handleDownloadClicked}>
                            <ButtonContent hidden>Export</ButtonContent>
                            <ButtonContent visible>
                                <Icon name='download' />
                            </ButtonContent>
                        </Button>
                    )
                }
            }
        }
        return 
    }

    renderImportButton() {
        return
        // if (!!this.props.user) {
        //     if (this.props.user.email === "seantan.mouse@gmail.com") {
        //         return <GridColumn floated='left' width={1}><input type="file" id="excel-upload"/></GridColumn>
        //     }
        // }
    }

    renderPagination() {
        if (this.props.clients == null) {
            return
        }
        var pagesRequired = 0
        if (this.state.showFilterOptions) {
            pagesRequired = Math.ceil(filteredClients.length / this.state.itemsPerPage)
        } else {
            pagesRequired = Math.ceil(this.props.clients.length / this.state.itemsPerPage)
        }
        return (
            <Pagination defaultActivePage={1} totalPages={pagesRequired} siblingRange={2} onPageChange={this.handlePageChange}/>
        )
    }

    renderFilterOptionButtons() {
        if (!!this.props.user) {
            if (!!this.props.user.role) {
                if (this.props.user.role.name.toLowerCase() === 'superadmin') {
                    return (
                        <div>
                            <Button style={filterButtonStyle} color='red' onClick={this.handleGenerateCorpSecReportClicked}>Generate Corp Sec Report</Button><Button onClick={this.handleClearAllFiltersClicked}>Clear all filters</Button>            
                        </div>
                    )
                }
            }
        }
        return <Button onClick={this.handleClearAllFiltersClicked}>Clear all filters</Button>
    }

    renderFYECheckboxes(dictFYE) {
        if (!this.props.canFilterByFYE) {
            return
        }
        return (
            <div>
                Company that has FYE in:<br />
                    <Checkbox style={checkboxStyle} name='1' label='Jan' checked={dictFYE[1]} onChange={this.handleFilterFYEClicked}/>
                    <Checkbox style={checkboxStyle} name='2' label='Feb' checked={dictFYE[2]} onChange={this.handleFilterFYEClicked}/>
                    <Checkbox style={checkboxStyle} name='3' label='Mar' checked={dictFYE[3]} onChange={this.handleFilterFYEClicked}/>
                    <Checkbox style={checkboxStyle} name='4' label='Apr' checked={dictFYE[4]} onChange={this.handleFilterFYEClicked}/>
                    <Checkbox style={checkboxStyle} name='5' label='May' checked={dictFYE[5]} onChange={this.handleFilterFYEClicked}/>
                    <Checkbox style={checkboxStyle} name='6' label='Jun' checked={dictFYE[6]} onChange={this.handleFilterFYEClicked}/>
                    <Checkbox style={checkboxStyle} name='7' label='Jul' checked={dictFYE[7]} onChange={this.handleFilterFYEClicked}/>
                    <Checkbox style={checkboxStyle} name='8' label='Aug' checked={dictFYE[8]} onChange={this.handleFilterFYEClicked}/>
                    <Checkbox style={checkboxStyle} name='9' label='Sep' checked={dictFYE[9]} onChange={this.handleFilterFYEClicked}/>
                    <Checkbox style={checkboxStyle} name='10' label='Oct' checked={dictFYE[10]} onChange={this.handleFilterFYEClicked}/>
                    <Checkbox style={checkboxStyle} name='11' label='Nov' checked={dictFYE[11]} onChange={this.handleFilterFYEClicked}/>
                    <Checkbox style={checkboxStyle} name='12' label='Dec' checked={dictFYE[12]} onChange={this.handleFilterFYEClicked}/><br />
            </div>
        )
    }

    renderMOACheckBoxes(dictMOA) {
        if (!this.props.canFilterByMonthOfAgreement) {
            return
        }
        return (
            <div>
                Company that has Month of Agreement (for Corp Sec) in:<br />
                <Checkbox style={checkboxStyle} name='1' label='Jan' checked={dictMOA[1]} onChange={this.handleFilterMonthOfAgreementClicked}/>
                <Checkbox style={checkboxStyle} name='2' label='Feb' checked={dictMOA[2]} onChange={this.handleFilterMonthOfAgreementClicked}/>
                <Checkbox style={checkboxStyle} name='3' label='Mar' checked={dictMOA[3]} onChange={this.handleFilterMonthOfAgreementClicked}/>
                <Checkbox style={checkboxStyle} name='4' label='Apr' checked={dictMOA[4]} onChange={this.handleFilterMonthOfAgreementClicked}/>
                <Checkbox style={checkboxStyle} name='5' label='May' checked={dictMOA[5]} onChange={this.handleFilterMonthOfAgreementClicked}/>
                <Checkbox style={checkboxStyle} name='6' label='Jun' checked={dictMOA[6]} onChange={this.handleFilterMonthOfAgreementClicked}/>
                <Checkbox style={checkboxStyle} name='7' label='Jul' checked={dictMOA[7]} onChange={this.handleFilterMonthOfAgreementClicked}/>
                <Checkbox style={checkboxStyle} name='8' label='Aug' checked={dictMOA[8]} onChange={this.handleFilterMonthOfAgreementClicked}/>
                <Checkbox style={checkboxStyle} name='9' label='Sep' checked={dictMOA[9]} onChange={this.handleFilterMonthOfAgreementClicked}/>
                <Checkbox style={checkboxStyle} name='10' label='Oct' checked={dictMOA[10]} onChange={this.handleFilterMonthOfAgreementClicked}/>
                <Checkbox style={checkboxStyle} name='11' label='Nov' checked={dictMOA[11]} onChange={this.handleFilterMonthOfAgreementClicked}/>
                <Checkbox style={checkboxStyle} name='12' label='Dec' checked={dictMOA[12]} onChange={this.handleFilterMonthOfAgreementClicked}/><br />
            </div>
        )
    }

    renderFilterOptions() {
        if (!this.props.canFilterByFYE && !this.props.canFilterByMonthOfAgreement) {
            return
        }
        if (this.state.showFilterOptions) {
            var dictFYE = {}
            var dictMOA = {}
            for (var i = 1; i < 13; i ++) {
                dictFYE[i] = false
                dictMOA[i] = false
            }
            for (let item of this.state.filterFYE) {
                dictFYE[item] = true
            }
            for (let item of this.state.filterMonthOfAgreement) {
                dictMOA[item] = true
            }
            return (
                <Segment color='red'>
                    <h4>Filter Options:</h4>
                    {this.renderFYECheckboxes(dictFYE)}
                    {this.renderMOACheckBoxes(dictMOA)}
                    {this.renderFilterOptionButtons()}
                </Segment>
            )
        }
    }

    renderClickMeButton() {
        return
        // return (<Button floated='right' animated='fade' color='red' onClick={this.handleMiscButtonClicked}>Click Me</Button>)
    }

    render() {
        let iconColor = this.state.showFilterOptions ? 'red' : 'grey'
        return (
            <Container>
                <br />{this.renderImportButton()}<br />
                <Grid>
                <GridColumn floated='left' width={5}><h1>FMD Clients</h1></GridColumn>
                    <GridColumn width={6}>
                        <Popup trigger={<Input fluid label={<Button icon='setting' color={iconColor} onClick={this.handleSettingsClicked}/>} 
                        labelPosition='right' placeholder='Search client...' onChange={this.handleChangeSearchField} />} on='focus' wide='very' position='bottom left' 
                        header='Client Search' content="Search by company name, group, or any authorised person's name, mobile or email. Click the settings button on the right to see filter options."/>
                    </GridColumn>
                    <GridColumn floated='right' width={5}>
                    <Button style={createButtonStyle} floated='right' animated='fade' color='red' onClick={() => { this.props.onChangeItem("new-client")}}>
                        <ButtonContent hidden>Create New Client</ButtonContent>
                        <ButtonContent visible>
                            <Icon name='add'/>
                        </ButtonContent>
                    </Button>
                    {this.renderClickMeButton()}
                    {this.renderDownloadButton()}
                    </GridColumn>
                </Grid>
                {this.renderFilterOptions()}
                {this.renderTable()}
                {this.renderPagination()}
                <br /><br /><br />
            </Container>
        )
    }

    handleMiscButtonClicked() {
        for (let client of this.props.clients) {
            if (!!client.audit) {
                if (!!client.audit.auditAppointmentDate || !!client.audit.consentLetterDate || !!client.audit.auditResignationLetterDate || !!client.audit.lastAccountAuditedDate) {
                    var dict = client.audit
                    if (!!client.audit.auditAppointmentDate) {
                        if (client.audit.auditAppointmentDate.split("-")[0].length === 2) {
                            dict['auditAppointmentDate'] = client.audit.auditAppointmentDate.split("-").reverse().join("-")
                        }
                        // dict[' '] = client.audit.auditAppointmentDate.split('T')[0].split("-").reverse().join("-")
                    }
                    if (!!client.audit.consentLetterDate) {
                        if (client.audit.consentLetterDate.split("-")[0].length === 2) {
                            dict['consentLetterDate'] = client.audit.consentLetterDate.split("-").reverse().join("-")
                        }
                    }
                    if (!!client.audit.auditResignationLetterDate) {
                        if (client.audit.auditResignationLetterDate.split("-")[0].length === 2) {
                            dict['auditResignationLetterDate'] = client.audit.auditResignationLetterDate.split("-").reverse().join("-")
                        }
                    }
                    if (!!client.audit.lastAccountAuditedDate) {
                        if (client.audit.lastAccountAuditedDate.split("-")[0].length === 2) {
                            dict['lastAccountAuditedDate'] = client.audit.lastAccountAuditedDate.split("-").reverse().join("-")
                        }
                    }
                    console.log(dict)
                    this.props.addAuditDataToAllClients({ mappingName: client.mappingName, audit: dict }, (statusCode) => {
                        console.log(client.mappingName)
                    })
                } else {
                    console.log("client has no audit date")
                }
            } else {
                console.log("client has no audit")
            }
        }
    }

    async handleGenerateCorpSecReportClicked() {
        var sheets = []
        sheets.push("Corp Sec")
        var data = []
        var keys = []
        var arr = generateHeaderRowForSheet(sheets[0])
        arr.splice(0, 0, { value: "ROC", fontWeight: "bold" })
        arr.splice(0, 0, { value: "Company Name", fontWeight: "bold" })
        data.push(arr)
        for (let field of fields) {
            if (field.departmentName === sheets[0]) {
                keys.push(field.field)
            }
        }
        for (let client of filteredClients) {
            var row = []
            if (!!client.corpSec) {
                for (let key of keys) {
                    let value = client.corpSec[key] ?? ""
                    let dict = { value }
                    row.push(dict)
                }
            }
            row.splice(0, 0, { value: client.clientInfo.roc })
            row.splice(0, 0, { value: client.clientInfo.companyName })
            data.push(row)
        }
        const blob = await writeXlsxFile([data], {sheets: sheets, stickyRowsCount: 1})
        let formattedDate = format(Date(), "d MMMM yyyy hh-mm a")
        saveAs(blob, `FMD-Corp-Sec-Report-${formattedDate}.xlsx`)
    }

    async handleDownloadClicked() {
        var sheets = []
        for (let dept of departments) {
            sheets.push(dept.deptName)
        }
        var data1 = [], data2 = [], data3 = [], data4 = [], data5 = [], data6 = [], data7 = []
        var keys1 = [], keys2 = [], keys3 = [], keys4 = [], keys5 = [], keys6 = [], keys7 = []
        var arr1 = generateHeaderRowForSheet(sheets[0])
        arr1.splice(0, 0, { value: "ROC", fontWeight: "bold" })
        arr1.splice(0, 0, { value: "Company Name", fontWeight: "bold" })
        data1.push(arr1)
        var arr2 = generateHeaderRowForSheet(sheets[1])
        arr2.splice(0, 0, { value: "ROC", fontWeight: "bold" })
        arr2.splice(0, 0, { value: "Company Name", fontWeight: "bold" })
        data2.push(arr2)
        var arr3 = generateHeaderRowForSheet(sheets[2])
        arr3.splice(0, 0, { value: "ROC", fontWeight: "bold" })
        arr3.splice(0, 0, { value: "Company Name", fontWeight: "bold" })
        data3.push(arr3)
        var arr4 = generateHeaderRowForSheet(sheets[3])
        arr4.splice(0, 0, { value: "ROC", fontWeight: "bold" })
        arr4.splice(0, 0, { value: "Company Name", fontWeight: "bold" })
        data4.push(arr4)
        var arr5 = generateHeaderRowForSheet(sheets[4])
        arr5.splice(0, 0, { value: "ROC", fontWeight: "bold" })
        arr5.splice(0, 0, { value: "Company Name", fontWeight: "bold" })
        data5.push(arr5)
        var arr6 = generateHeaderRowForSheet(sheets[5])
        arr6.splice(0, 0, { value: "ROC", fontWeight: "bold" })
        arr6.splice(0, 0, { value: "Company Name", fontWeight: "bold" })
        data6.push(arr6)
        var arr7 = generateHeaderRowForSheet(sheets[6])
        arr7.splice(0, 0, { value: "ROC", fontWeight: "bold" })
        arr7.splice(0, 0, { value: "Company Name", fontWeight: "bold" })
        data7.push(arr7)
        for (let field of fields) {
            if (field.departmentName === sheets[0]) {
                keys1.push(field.field)
            }
            if (field.departmentName === sheets[1]) {
                keys2.push(field.field)
            }
            if (field.departmentName === sheets[2]) {
                keys3.push(field.field)
            }
            if (field.departmentName === sheets[3]) {
                keys4.push(field.field)
            }
            if (field.departmentName === sheets[4]) {
                keys5.push(field.field)
            }
            if (field.departmentName === sheets[5]) {
                keys6.push(field.field)
            }
            if (field.departmentName === sheets[6]) {
                keys7.push(field.field)
            }
        }
        for (let client of this.props.clients) {
            var row1 = [], row2 = [], row3 = [], row4 = [], row5 = [], row6 = [], row7 = []
            for (let k1 of keys1) {
                let value = client.clientInfo[k1] ?? ""
                let dict = { value }
                row1.push(dict)
            }
            if (!!client.audit) { // uploading of department info not done yet
                for (let k2 of keys2) {
                    let value = client.audit[k2] ?? ""
                    let dict = { value }
                    row2.push(dict)
                }   
            }
            if (!!client.businessDev) {
                for (let k3 of keys3) {
                    let value = client.businessDev[k3] ?? ""
                    let dict = { value }
                    row3.push(dict)
                }
            }
            if (!!client.operations) {
                for (let k4 of keys4) {
                    let value = client.operations[k4]  ?? ""
                    let dict = { value }
                    row4.push(dict)
                }
            }
            if (!!client.corpSec) {
                for (let k5 of keys5) {
                    let value = client.corpSec[k5] ?? ""
                    let dict = { value }
                    row5.push(dict)
                }
            }
            if (!!client.outsourcedAccounting) {
                for (let k6 of keys6) {
                    let value = client.outsourcedAccounting[k6] ?? ""
                    let dict = { value }
                    row6.push(dict)
                }
            }
            if (!!client.taxFS) {
                for (let k7 of keys7) {
                    let value = client.taxFS[k7] ?? ""
                    let dict = { value }
                    row7.push(dict)
                }
            }
            data1.push(row1)
            row2.splice(0, 0, { value: client.clientInfo.roc })
            row2.splice(0, 0, { value: client.clientInfo.companyName })
            data2.push(row2)
            row3.splice(0, 0, { value: client.clientInfo.roc })
            row3.splice(0, 0, { value: client.clientInfo.companyName })
            data3.push(row3)
            row4.splice(0, 0, { value: client.clientInfo.roc })
            row4.splice(0, 0, { value: client.clientInfo.companyName })
            data4.push(row4)
            row5.splice(0, 0, { value: client.clientInfo.roc })
            row5.splice(0, 0, { value: client.clientInfo.companyName })
            data5.push(row5)
            row6.splice(0, 0, { value: client.clientInfo.roc })
            row6.splice(0, 0, { value: client.clientInfo.companyName })
            data6.push(row6)
            row7.splice(0, 0, { value: client.clientInfo.roc })
            row7.splice(0, 0, { value: client.clientInfo.companyName })
            data7.push(row7)
        }
        const blob = await writeXlsxFile([data1, data2, data3, data4, data5, data6, data7], {sheets: sheets, stickyRowsCount: 1})
        let formattedDate = format(Date(), "d MMMM yyyy hh-mm a")
        saveAs(blob, `FMD-Customer-Database-${formattedDate}.xlsx`)
    }

    handleFilterFYEClicked = (e, {name, checked}) => {
        if (checked) {
            var arr = [...this.state.filterFYE]
            arr.push(name)
            this.setState({ filterFYE: arr })
        } else {
            var arr2 = [...this.state.filterFYE]
            const index = arr2.indexOf(name)
            if (index > -1) { 
                arr2.splice(index, 1)
                this.setState({ filterFYE: arr2 })
            }
        }
    }

    handleFilterMonthOfAgreementClicked = (e, {name, checked}) => {
        if (checked) {
            var arr = [...this.state.filterMonthOfAgreement]
            arr.push(name)
            this.setState({ filterMonthOfAgreement: arr })
        } else {
            var arr2 = [...this.state.filterMonthOfAgreement]
            const index = arr2.indexOf(name)
            if (index > -1) { 
                arr2.splice(index, 1)
                this.setState({ filterMonthOfAgreement: arr2 })
            }
        }
    }

    handleClearAllFiltersClicked = () => {
        var arr = []
        this.setState({ filterFYE: arr, filterMonthOfAgreement: arr })
    }

    handleSettingsClicked = e => {
        this.setState({ showFilterOptions: !this.state.showFilterOptions })
    }

    handleChangeSearchField = e => {
        const { value } = e.target
        if (value.length < 2 && !this.state.isSearching) {
            return
        }
        if (value.length < 2) {
            this.setState({ isSearching: false })
        } else {
            this.setState({ isSearching: true, searchString: value })
        }
    }

    handlePageChange = (e, { activePage}) => {
        let page = activePage-1
        this.setState({ activePage: page })
    }

    handleEditClicked(client) {
        this.props.onChangeItem('edit-client', client)
    }

    handleDocumentsClicked(client) {
        this.props.onChangeItem('documents-client', client)
    }

    handleViewClicked(client) {
        this.props.onChangeItem('view-client', client)
    }

    handleDeleteClicked = client => {
        if (this.state.showDeleteConfirmationPopup) return
        const contentString = "Delete client '" + client.clientInfo.companyName + "'?"
        this.setState({
            idToDelete: client._id,
            deleteConfirmContentString: contentString,
            showDeleteConfirmationPopup: true
        })
    }

    handleDeleteCancel = () =>
        this.setState({ showDeleteConfirmationPopup: false })

    handleDeleteConfirm = () => {
        const dict = { id: this.state.idToDelete }
        this.props.deleteClient(dict, () => {
            this.setState({ showDeleteConfirmationPopup: false })
            this.props.fetchClients()
            this.props.onDeleteSuccess()
        })
    }
}

function generateHeaderRowForSheet(sheet) {
    var arr = []
    for (let field of fields) {
        if (field.departmentName === sheet) {
            arr.push(field)
        } 
    }
    var headerRow = []
    for (let field2 of arr) {
        let dict = { value: field2.fieldName, fontWeight: 'bold'}
        headerRow.push(dict)
    }
    return headerRow
}

function doesClientSatisfyFilters(client, filterFYE, filterMonthOfAgreement) {
    if (filterFYE.length === 0 && filterMonthOfAgreement.length === 0) {
        return true
    }
    if (client.corpSec == null) {
        return false
    }
    if (filterFYE.length !== 0 && client.corpSec.FYE == null) {
        return false
    }
    if (filterMonthOfAgreement.length !== 0 && client.corpSec.monthOfAgreement == null) {
        return false
    }
    if (filterFYE.length !== 0 && !filterFYE.includes(client.corpSec.FYE)) {
        return false
    }
    if (filterMonthOfAgreement.length !== 0 && !(client.corpSec.monthOfAgreement.some(element => filterMonthOfAgreement.includes(element)))) {
        return false
    }
    return true
}

function mapStateToProps({ clients, roles, user }) {
    return { clients, roles, user }
}


// var DispatchGroup = (function() {
//     var nextId = 0

//     function DispatchGroup() {
//         var id = ++nextId
//         var tokens = new Set()
//         var onCompleted = null

//         function checkCompleted() {
//             if(!tokens.size) {
//                 if(onCompleted) {
//                     onCompleted()
//                     console.log('group ' + id + ' completed')
//                 }
//             }
//         }

//         // the only requirement for this is that it's unique during the group's cycle
//         function nextToken() {
//             return Date.now() + Math.random()
//         }

//         this.enter = function () {
//             let token = nextToken()
//             tokens.add(token)
//             return token
//         }

//         this.leave = function (token) {
//             if(!token) throw new Error("'token' must be the value earlier returned by '.enter()'")
//             tokens.delete(token)
//             checkCompleted()
//         }

//         this.notify = function (whenCompleted) {
//             if(!whenCompleted) throw new Error("'whenCompleted' must be defined")
//             onCompleted = whenCompleted
//             checkCompleted()
//         }
//     }

//     return DispatchGroup;
// })()

export default connect(mapStateToProps, actions)(Clients)

